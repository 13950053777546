/*
 * Rather than the Form.Control, or <input>, rendering the borders and box-shadows, the Boostrap InputGroup does
 *
 * The InputGroup.Prepend, InputGroup.Append, and both the SearchIcon and CloseIcon receive different spacing and sizing
 * values depending on whether the lg or default size is used
*/
.lia-input-group {
  display: flex;
  align-items: center;
  border: 1px solid var(--lia-bs-input-border-color);
  background: var(--lia-bs-white);
  transition: border-color var(--lia-timing-fast) var(--lia-timing-function),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);

  /* applies focus styles to the InputGroup when the child Form.Control <input> receives focus */
  &:has(.lia-input-field:focus) {
    border-color: var(--lia-bs-input-focus-border-color);
    box-shadow: var(--lia-bs-input-focus-box-shadow);
    outline: 0;
  }
  &-default {
    border-radius: var(--lia-bs-input-border-radius);
  }
  &-lg {
    border-radius: var(--lia-bs-input-border-radius-lg);
  }

  /* Form.Control <input> styles */
  .lia-input-field {
    border: none;
    box-shadow: none;
    border-radius: inherit;
    font-size: var(--lia-bs-font-size-base);

    &::placeholder {
      font-size: var(--lia-bs-font-size-base);
      color: var(--lia-bs-gray-600);
    }
    &-default {
      padding: 7px 15px;
    }
    &-lg {
      padding-left: 15px;
      padding-right: 25px;
    }
  }
}

/* InputGroup.Prepend styles */
.lia-input-group-prepend {
  display: flex;
  align-items: center;

  &-default {
    padding-left: 15px;
  }
  &-lg {
    padding-left: 25px;
  }
}

/* InputGroup.Append styles */
.lia-input-group-append {
  display: flex;
  align-items: center;
  /* applies space between loading icon and button - the addition of the div also breaks some BS styles which force
     border radius styles on button elements that are direct children of InputGroup.Append
    */
  .lia-btn-wrapper {
    margin-left: 16px;
  }

  &-default {
    padding-right: 10px;
  }
  &-lg {
    padding-right: 20px;
  }
}
